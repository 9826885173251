import React from 'react';
import QueryString from 'query-string';
import { Container } from '../components/Container';
import { Layout } from '../components/Layout';
import { Topbar } from '../components/Topbar';

const SubscriptionPlans = () => {
  const searchNoWindow = typeof window !== 'undefined' ? window.location.search : '';
  const paramsNoWindow = QueryString.parse(searchNoWindow);

  const textNoWindow = `Olá, gostaria de continuar com a ativação do meu plano.
Segue abaixo as informações:
Nome - *${paramsNoWindow?.nome ?? ''}*
Plano - *${paramsNoWindow?.plano ?? ''}*
Email - *${paramsNoWindow?.email ?? ''}*`;

  return (
    <Layout>
      <Topbar />
      <Container>
        <section className="tc mb40 mt32">
          <h1 className="f20 f24-ns f30-m f30-l abbey ttu ma0 mb8">Em Manuntenção</h1>
          <p className="f14 f16-ns fw3 boulder ma0 lh-copy mb8">
            Pedimos desculpa pelo transtorno.
            <br />
            Nosso sistema de pagamento está passando por uma reformulação neste momento.
          </p>
          <h2 className="f18 f22-ns f28-m f28-l abbey ttu ma0 mb8">
            PARA CONCLUIR A ATIVAÇÃO DO SEU PLANO,{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://wa.me/553499951500?text=${encodeURI(textNoWindow)}`}
            >
              CLIQUE AQUI.
            </a>
          </h2>
          <p className="f14 f16-ns fw3 boulder ma0 lh-copy mb8">
            Você pode prosseguir com a ativação do seu plano imediatamente clicando no link e
            finalizando o pagamento com a assistência do nosso suporte.
          </p>
        </section>
      </Container>
    </Layout>
  );
};

export default SubscriptionPlans;
